// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';

import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, description } = post.frontmatter;
  const location = useLocation();


  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        <TwitterShareButton url={location.href} title={title}>
          <TwitterIcon round size={32} />
        </TwitterShareButton>
        <LinkedinShareButton style={{ marginLeft: 8 }} url={location.href} title={title} summary={description}>
          <LinkedinIcon round size={32} />
        </LinkedinShareButton>
        <WhatsappShareButton style={{ marginLeft: 8 }} url={location.href} title={title} summary={description}>
          <WhatsappIcon round size={32} />
        </WhatsappShareButton>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>
      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
